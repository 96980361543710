'use client';

import { useEffect } from 'react';

declare global {
	interface Window {
		unlockABTasty?: () => void;
		lockABTastyTag?: boolean;
	}
}

export const LoadABTasty = () => {
	useEffect(() => {
		// As soon as client is rendered, AB-tasty can be used (otherwise NextJS hydration errors will pop up)
		// See: https://developers.abtasty.com/docs/tag/tag-window#locking-tag-execution
		if (typeof window.unlockABTasty === 'function') {
			window.unlockABTasty();
		} else {
			// If AB-tasty is not loaded yet
			// don't lock it to begin with
			delete window.lockABTastyTag;
		}
	}, []);

	return (
		<>
			{/* AB-tasty will be locked from updating DOM until it is fully loaded (this is needed to prevent hydration errors with NextJS). */}
			<script type="text/javascript">{`window.lockABTastyTag = true;`}</script>
			<script
				type="text/javascript"
				async
				src="https://try.abtasty.com/5891b250c370c1cdcef9030bbd1d78fb.js"
			></script>
		</>
	);
};
